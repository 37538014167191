<template>
  <div id="dashboard">
    <template
        v-if="user==='marlon@vialex.pe' || user === 'christian@salsa.pe' || user === 'cristhian.bardalez@vialex.pe' || user === 'mercedes@vialex.pe'">
      <div v-if="initProgress" class="w-100 mt-5 mb-5">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
      <div class="dashboard__header">
        <vx-card collapse="false" ref="filterCard" title="Rango de fechas" class="user-list-filters mb-8"
                 collapse-action
                 @refresh="resetFilters">
          <!--Filter by date-->
          <div class="dashboard__filter">
            <div>
              <datepicker label="Desde" v-model="startDate"
                          id="startDate"
                          v-validate="'required'" name="startDate"></datepicker>
            </div>
            <div>
              <datepicker label="Gasta" v-model="endDate" id="endDate"
                          v-validate="'required'" name="startDate"></datepicker>
            </div>
          </div>
          <div class="vx-col mt-2">
            <vs-button @click="filterByDate" class="mr-6 vs-align-right">Filtrar</vs-button>
          </div>
        </vx-card>
      </div>
      <div class="dashboard__content">
        <div class="dashboard__card">
          <h1>Total de ventas</h1>
          <span>{{ 'S/ ' + Intl.NumberFormat('en-US', {}).format(total) }}</span>
        </div>
        <div class="dashboard__card">
          <h1>Total de comprobantes</h1>
          <span>{{ Intl.NumberFormat('en-US', {}).format(sales.length) }}</span>
        </div>
        <div class="dashboard__card">
          <h1>Total de productos</h1>
          <span>{{
              Intl.NumberFormat('en-US', {}).format(products.reduce((a, c) => { return a += c.quantity}, 0))
            }} und</span>
        </div>
      </div>
      <div class="dashboard__resume dashboard__resume--one mb-12">
        <div>
          <h2>Ranking de locales</h2>
          <vs-table class="mt-5" :data="establishments">

            <template slot="thead">
              <vs-th>Local</vs-th>
              <vs-th>Total</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                <vs-td :data="tr.name">
                  <p>{{ tr.name }}</p>
                </vs-td>

                <vs-td :data="tr.price">
                  <p>S/ {{
                      Intl.NumberFormat('en-US', {}).format(tr.total)
                    }}</p>
                </vs-td>

              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
      <div class="dashboard__resume dashboard__resume--one mb-12">
        <div>
          <h2>Ranking de vendedores</h2>
          <vs-table class="mt-5" :data="seller">

            <template slot="thead">
              <vs-th>Nombre</vs-th>
              <vs-th>Total</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                <vs-td>
                  <p>{{ tr.firstname + ' ' + tr.lastname }}</p>
                </vs-td>

                <vs-td :data="tr.total">
                  <p>S/ {{
                      Intl.NumberFormat('en-US', {}).format(tr.total)
                    }}</p>
                </vs-td>

              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
      <div class="dashboard__resume dashboard__resume--one">
        <div>
          <h2>Productos vendidos</h2>
          <vs-table pagination :max-items="10" class="mt-5" :data="products">

            <template slot="thead">
              <vs-th>Nombre</vs-th>
              <vs-th>SKU</vs-th>
              <vs-th>Cantidad</vs-th>
              <vs-th>Precio</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                <vs-td :data="tr.name">
                  <p>{{ tr.name }}</p>
                </vs-td>

                <vs-td :data="tr.sku">
                  <p> {{ tr.sku }}</p>
                </vs-td>

                <vs-td :data="tr.quantity">
                  <p>{{ tr.quantity }}</p>
                </vs-td>

                <vs-td style="width: 15% !important;" :data="tr.price">
                  <p>S/ {{
                      Intl.NumberFormat('en-US', {}).format(tr.price * 1.18)
                    }}</p>
                </vs-td>

              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import { auth, db, Timestamp } from '@/firebase/firebaseConfig'
import Datepicker from 'vuejs-datepicker'

export default {
  name: 'Home',
  components: {
    Datepicker
  },
  computed: {
    user () {
      return auth.currentUser.email
    }
  },
  data () {
    return {
      sales: [],
      products: [],
      total: 0,
      initProgress: false,
      establishments: [
        { name: 'Local 447', total: 0 },
        { name: 'Local 461', total: 0 },
        { name: 'Local Leticia', total: 0 },
        { name: 'Local Revelados', total: 0 }
      ],
      startDate: null,
      endDate: null,
      seller: []
    }
  },
  async created () {
    try {
      if (this.user === 'marlon@vialex.pe' || this.user === 'christian@salsa.pe' || this.user === 'cristhian.bardalez@vialex.pe' || this.user === 'mercedes@vialex.pe') {
        this.initProgress = true
        await this.loadCurrentSales()
      }
    } catch (e) {
      console.log(e)
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  methods: {
    /**
     * Load current sales
     * @returns {Promise<void>}
     */
    async loadCurrentSales () {
      this.sales = []
      // Set start date
      const startDate = new Date()
      const endDate = new Date()
      startDate.setHours(0)
      startDate.setMinutes(0)
      // Set end date
      endDate.setHours(23)
      endDate.setMinutes(59)

      const starTimes = Timestamp.fromDate(startDate)
      const endTimes = Timestamp.fromDate(endDate)

      const querySnapshot = await db.collection('sales')
          .where('issueDate', '>=', starTimes)
          .where('issueDate', '<=', endTimes).get()

      querySnapshot.forEach((doc) => {
        if (doc.data().sunatDocumentType === '01' || doc.data().sunatDocumentType === '03') {
          this.sales.push({
            id: doc.id,
            ...doc.data()
          })
          console.log(doc.data())
          this.total += doc.data().footer.total
          // Load products
          doc.data().detail.forEach((p) => {
            this.products.push({
              ...p
            })
          })
          // Load establishments
          const local = this.establishments.find((e) => e.name === doc.data().establishment.name)
          local.total += doc.data().footer.total
          // Load seller
          const sellerIndex = this.seller.findIndex((s) => s._id === doc.data().user._id)
          if (sellerIndex === -1) {
            this.seller.push({
              ...doc.data().user,
              total: doc.data().footer.total
            })
          } else {
            this.seller[sellerIndex].total += doc.data().footer.total
          }
        }
      })
      this.products.sort((a, b) => {return b.quantity - a.quantity})
      this.seller.sort((a, b) => {return b.total - a.total})
    },
    /**
     * Filter by date
     */
    async filterByDate () {
      try {
        if (this.startDate && this.endDate) {
          this.initProgress = true
          this.resetFilters()
          // Set start date
          this.startDate.setHours(0)
          this.startDate.setMinutes(0)
          // Set end dateU
          this.endDate.setHours(23)
          this.endDate.setMinutes(59)

          const starTimes = Timestamp.fromDate(this.startDate)
          const endTimes = Timestamp.fromDate(this.endDate)
          let querySnapshot = {}

          querySnapshot = await db.collection('sales')
              .where('issueDate', '>=', starTimes)
              .where('issueDate', '<=', endTimes).get()

          querySnapshot.forEach((doc) => {
            if (doc.data().sunatDocumentType === '01' || doc.data().sunatDocumentType === '03') {
              this.sales.push({
                id: doc.id,
                ...doc.data()
              })
              this.total += doc.data().footer.total
              // Load products
              doc.data().detail.forEach((p) => {
                this.products.push({
                  ...p
                })
              })
              // Load establishments
              const local = this.establishments.find((e) => e.name.trim() == doc.data().establishment.name.trim())
              console.log(this.establishments)
              console.log(doc.data().establishment.name.trim())
              console.log(local)
              local.total += doc.data().footer.total
              // Load seller
              const sellerIndex = this.seller.findIndex((s) => s._id === doc.data().user._id)
              if (sellerIndex === -1) {
                this.seller.push({
                  ...doc.data().user,
                  total: doc.data().footer.total
                })
              } else {
                this.seller[sellerIndex].total += doc.data().footer.total
              }
            }
          })
          this.products.sort((a, b) => {return b.quantity - a.quantity})
          this.seller.sort((a, b) => {return b.total - a.total})
        }
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Reset filters
     * @returns {Promise<void>}
     */
    resetFilters () {
      try {
        this.total = 0
        this.sales = []
        this.products = []
        this.seller = []
        this.establishments = [
          { name: 'Local 447', total: 0 },
          { name: 'Local 461', total: 0 },
          { name: 'Local Leticia', total: 0 },
          { name: 'Local Revelados', total: 0 }
        ]
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      }
    }
  }
}
</script>
